.footer {
  background: #000000;
  padding-bottom: 50px;
  padding-top: 50px;
  &-content {
    max-width: 450px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 992px) {
      max-width: none;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-left {
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      text-align: center;
      @media (min-width: 992px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  &-right {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    @media (min-width: 992px) {
      margin-top: 0px;
    }
  }

  &-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-right: 16px;
    @media (min-width: 992px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
