//Colors
$blue: #1F3470;
$red: #FA6B6B;
$green: #0DB6AE;
$purple: #940E86;

//State Colors
$info: #2F80ED;
$success: #27AE60;
$warning: #E2B93B;
$error: #FF0000;

//Black Colors
$blc1: #000000;
$blc2: #1D1D1D;
$blc3: #282828;
$blc4: #2E2E2E;

$whl: #FFFFFF;

//Grey Colors

$grey1: #333333;
$grey2: #4F4F4F;
$grey3: #828282;
$grey4: #BDBDBD;
$grey5: #E0E0E0;


//Fonts
$Montserrat: 'Montserrat', sans-serif;
$GothamPro:'Gotham Pro', sans-serif;
$GothamProBold:'Gotham Pro Bold', sans-serif;
$MontserratBold:'Montserrat-Bold', sans-serif;

* {
  font-family: $GothamPro;
}
.container {
  max-width: 1110px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.buttons {
  .btn {
    max-width: 280px;
    width: 100%;
    padding: 16px 10px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;

    &:disabled {
      cursor: not-allowed;
      background: #E0E0E0;

      &:hover {
        background: #E0E0E0;
        cursor: not-allowed;
      }
    }
  }

  .button-red {
    background: #FA6B6B;

    &:hover {
      cursor: pointer;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #FA6B6B;
    }

    &:active {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #FA6B6B;
    }
  }

  .button-green {
    background: #0DB6AE;

    &:hover {
      cursor: pointer;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #0DB6AE;
    }

    &:active {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #0DB6AE;
    }
  }

  .button-blue {
    background: #1E88E5;

    &:hover {
      cursor: pointer;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #1E88E5;
    }

    &:active {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #1E88E5;
    }
  }

  .button-transparent {
    background: transparent;
    border: 2px solid #FA6B6B;
    color: #FA6B6B;
    padding: 14px 10px;

    &:hover {
      cursor: pointer;
      color: #FFFFFF;
      background: #FA6B6B;
    }

    &:active {
      //background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #1E88E5;
    }
  }

  .btn-icon {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 12px 16px;
    font-family: $GothamPro;
    font-weight: 700;
    color: #FFFFFF;
    max-width: 280px;
    width: 100%;
    background: #FA6B6B;
    border-radius: 8px;

    img,
    svg {
      margin-right: 20px;
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #FA6B6B;
    }

    &:active {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #FA6B6B;
    }

    &:disabled {
      background: #E0E0E0;
      cursor: not-allowed;
    }
  }

  .icon-plus {
    width: 56px;
    height: 56px;
    filter: drop-shadow(0px 4px 34px rgba(9, 44, 76, 0.24));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FA6B6B;

    &:hover {
      cursor: pointer;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #FA6B6B;
    }

    &:active {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #FA6B6B;
    }

    &:disabled {
      background: #E0E0E0;

      .icon-plus__img {
        path {
          stroke: #BDBDBD;
        }
      }
    }
  }
}


.form-element {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: $GothamPro;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;

  &-block {
    position: relative;


    .icon {
      padding: 16px 54px 16px 24px;
    }

    &--width {
      max-width: 356px;
      width: 100%;
    }
  }

  &__label {
    color: $grey2;
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 22px;
    font-family: $GothamProBold;
    margin-bottom: 8px;
    display: block;
  }

  &__input {
    border: 1px solid $grey4;
    border-radius: 4px;
    width: 100%;
    padding: 14px 16px;

    &:hover {
      cursor: pointer;
    }

    &::placeholder {
      color: $grey2;
      font-size: 14px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    @media (min-width: 768px) {
      padding: 16px 28px;
    }
  }

  &__icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  &__textarea {
    border: 1px solid $grey4;
    border-radius: 4px;
    padding: 14px 16px;
    width: 100%;
    resize: none;

    &::placeholder {
      color: #E0E0E0;
    }

    @media (min-width: 768px) {
      padding: 16px 24px;
    }
  }

  &__password {
    position: absolute;
    height: 16px;
    width: 22px;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 100%;

      &:last-child {
        position: absolute;
        top: 0;
        opacity: 0;
      }
    }

    .password-on {
      display: none;
    }
  }

  .status {
    min-height: 20px;

  }

  .invalid-feedback {
    min-height: 20px;
    color: $error;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
  }

  .error {
    color: #EB5757;
    font-size: 14px;
    line-height: 20px;
    display: none;
    padding: 0;
    //margin-top: 8px;
  }

  .warning {
    color: $warning;
    font-size: 14px;
    line-height: 20px;
    display: none;
  }

  .success {
    color: $success;
    font-size: 14px;
    line-height: 20px;
    display: none;
  }
}


.body-hidden {
  overflow: hidden !important;
}
