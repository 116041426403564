.header {
  background: #000000;
  padding-bottom: 10px;
  padding-top: 10px;
  @media (min-width: 992px) {
    padding-bottom: 0px;
    padding-top: 24px;
  }
  &:after {
    content: '';
    top: 52px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0%;
    width: 100%;
    background: #00091d;
    position: fixed;
    z-index: 100;
    transition: all 0.3s ;
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-left {
    @media (min-width: 992px) {
     padding-bottom: 20px;
    }
  }

  &-right {

    @media (min-width: 992px) {
      width: 70%;
    }
  }

  &-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
    position: fixed;
    top: -100vh;
    left: 0;
    right: 0;
    z-index: 200;
    padding: 36px 16px 60px 16px;
    transition: all 0.3s ;
    @media (min-width: 992px) {
      align-items: center;
      flex-direction: row;
      position: static;
      padding: 0;
      background: transparent;
    }
    &__link {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-top: 24px;
      &:first-child {
        margin-top: 0;
      }
      @media (min-width: 992px) {
        color: #FFFFFF;
        margin-top: 0;
        padding-bottom: 20px;
        position: relative;
        &:hover  {
          color: #FA6B6B;
          //font-weight: 700;
          transform: scale(1.05);
          cursor: pointer;
          &:before {
            height: 1px;
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0;
            background: #FA6B6B;
          }
        }
      }
    }
  }
  .toggle-menu {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 1;
    @media (min-width: 992px) {
      display: none;
    }
    span,
    span:before,
    span:after {
      display: block;
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: #FFFFFF;
      transition-duration: .25s;
    }

    span::before {
      content: '';
      top: -8px;
    }

    span::after {
      content: '';
      top: 8px;
    }

    &.active {
      span {
        transform: rotate(45deg);
      }

      span::before {
        top: 0;
        transform: rotate(0);
      }

      span::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
  }
  &.active {
    &::after {
      height: 100%;
      transition: all 0.3s ;
    }
    .header-list {
      top: 52px;
      transition: all 0.3s ;
    }
  }
}
