.main-page {
  background: #00091d;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    @media (min-width: 992px) {
      font-size: 40px;
      line-height: 44px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .hero {
    padding-top: 36px;
    padding-bottom: 40px;
    background-image: url("/img/hero/hero_seo_bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    @media (min-width: 992px) {
      padding-bottom: 45px;
    }
    &-content {
      max-width: 450px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: none;
        min-height: 538px;
      }
    }
    &-left {
      text-align: center;
      @media (min-width: 992px) {
        text-align: left;
      }
      b {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #FA6B6B;
        text-align: center;
        @media (min-width: 992px) {
          font-size: 40px;
          line-height: 44px;
        }
      }
      .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        max-width: 350px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 992px) {
          font-size: 40px;
          line-height: 44px;
          text-align: left;
          max-width: 400px;
          margin-left: 0;
        }
        span {
          display: inline-block;
          img {
            width: 24px;
            height: 24px;
            @media (min-width: 992px) {
              width: 34px;
              height: 34px;
            }
          }
        }
      }
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 16px;
        @media (min-width: 992px) {
          font-size: 18px;
          line-height: 24px;
          text-align: left;
          max-width: 400px;
          margin-top: 24px;
        }
      }
      .trusted {
        margin-top: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        @media (min-width: 992px) {
          font-size: 18px;
          line-height: 24px;
          text-align: left;
          margin-top: 44px;
        }
      }
      .logo{
        margin-top: 16px;
        display: flex;
        align-items: center;
        @media (min-width: 992px) {
          margin-top: 10px;
        }
        &-item {
          margin-left: 16px;
          width: calc(33% - 16px);
          &:first-child {
            margin-left: 0;
          }
        }
      }

    }
    &-right {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      @media (min-width: 992px) {
        max-width: 475px;
        width: 100%;
      }
      .form {
        width: 100%;
        .form-element {
          margin-bottom: 4px;
          &__textarea {
            height: 84px;
          }
          &__textarea, &__input  {
            border: 1px solid #BDBDBD;
            border-radius: 4px;
            background: #00091d;
            color: #FFFFFF;
            &::placeholder {
              color: #4F4F4F;
            }
          }
        }
        .buttons {
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (min-width: 992px) {
            margin-top: 52px;
            justify-content: center;
          }
        }
      }
      .done {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 100px;
          height: 100%;
          @media (min-width: 992px) {
            width: 160px;
          }
        }
        .title {
          margin-top: 24px;
        }
        .description {
          margin-top: 8px;
          @media (min-width: 992px) {
            font-size: 16px;
            line-height: 22px;
            margin-top: 16px;
          }
        }
      }
    }
  }

  .our-services {
    padding-top: 20px;
    padding-bottom: 45px;
    @media (min-width: 992px) {
      padding-top: 45px;
      padding-bottom: 90px;
    }
    .title {

    }

    .description {
      margin-top: 24px;
    }

    &-content {
      margin-top: 24px;
    }

    &-wrapper {

    }

    &-slide {
      .images {
        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
        }
      }
      .name {
        margin-top: 8px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
        text-transform: capitalize;
        @media (min-width: 992px) {
          font-size: 16px;
          line-height: 22px;
          margin-top: 24px;
        }
      }
    }

    .our-services-navigation {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px;
      margin-top: 30px;
      .swiper-pagination-bullets-dynamic {
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        width: 55% !important;
        @media (min-width: 992px) {
          width: 60% !important;
        }
      }
      .swiper-button-prev, .swiper-button-next {
        outline: none;
        position: static;
        margin: 0;
      }
      .swiper-button-prev {
        &:after {
          content: url("/img/arrow_back-swiper.svg");
        }
      }
      .swiper-button-next {
        &:after {
          content: url("/img/arrow_next-swiper.svg");
        }
      }
      .swiper-pagination {
        .swiper-pagination-bullet {
          background: #E0E0E0;
          width: 20px;
          height: 20px;
          opacity: 1;
          transition: 0.3s;
          transform: scale(0.66);
          margin-left: 7px;
          @media (min-width: 992px) {
            margin-left: 24px;
          }
          &:first-child {
            margin-left: 0;
          }
        }
        .swiper-pagination-bullet-active {
          background: #FA6B6B;
          transform: scale(1);
        }
      }
    }
  }

  .our-vehicles {
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
    @media (min-width: 992px) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
    &-content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 16px;
      margin-top: 24px;
      @media (min-width: 450px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 16px;
      }
      @media (min-width: 750px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (min-width: 992px) {
        grid-column-gap: 40px;
        grid-row-gap: 24px;
        margin-top: 36px;
      }
    }
    &-item {
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      .images {
        height: 140px;
        @media (min-width: 992px) {
          height: 186px;
        }
        img {
          height: 100%;
          width: 100%;
          border-radius: 8px 8px 0px 0px;
          object-fit: cover;
        }
      }
      .content {
        padding: 16px 16px;
        .name {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
          text-align: center;
        }
        .info {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 8px;
          @media (min-width: 768px) {
            margin-top: 12px;
          }
          &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 4px;
            &:first-child {
              margin-top: 0;
            }
            p,sup {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #4F4F4F;
            }
            &__right {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .advantages {
    padding-top: 20px;
    padding-bottom: 20px;
    @media (min-width: 992px) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 450px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: 992px) {
        max-width: none;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &-left {
      @media (min-width: 992px) {
        max-width: 500px;
        width: 100%;
        .title {
          text-align: left;
        }
      }
    }

    &-right {

    }

    &-list {
      display: flex;
      flex-direction: column;
      @media (min-width: 992px) {
        margin-top: 60px;
      }
      &-item {
        display: flex;
        border-bottom: 1px solid #1F3470;
        padding-bottom: 12px;
        margin-top: 16px;
        &:first-child {
          //margin-top: 0;
        }
        &:last-child {
          border-bottom: none;
        }
        svg {
          flex-shrink: 0;
        }
        p {
          margin-left: 16px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
          @media (min-width: 992px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
    &-card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 16px;
      margin-top: 24px;
      &-item {
        background: #0F1938;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 14px;
        @media (min-width: 992px) {
          padding: 36px 36px 25px 36px;
          width: 225px;
          //width: 100%;
        }
        svg {
          @media (min-width: 992px) {
            width: 90px;
            height: 90px;
          }
        }
        p {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #FFFFFF;
          margin-top: 16px;
          max-width: 130px;
          width: 100%;
          @media (min-width: 768px) {
            font-size: 16px;
            line-height: 22px;
            max-width: 153px;
          }
        }
      }
    }
  }

  .customer-speak {
    padding-top: 120px;
    padding-bottom: 130px;
    background-image: url("/img/hero/customer_speak_bg.png");
    background-repeat: no-repeat;
    @media (min-width: 992px) {
      padding-top: 170px;
      padding-bottom: 170px;
      background-position: center;
      background-size: cover;
    }
    &-content {
      background: #0F1938;
      border-radius: 10px;
      padding: 32px 16px 42px 26px;
      max-width: 450px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 24px;
      @media (min-width: 992px) {
        max-width: none;
        padding: 40px 35px 45px 65px;
      }
    }
    &-item {
      .user {
        display: flex;
        &-right {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          @media (min-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: flex-start;
          }
          .user-info {
            .name {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: #FFFFFF;
              @media (min-width: 992px) {
                font-size: 24px;
                line-height: 24px;
              }
            }
            .career {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #FFFFFF;
              margin-top: 4px;
              @media (min-width: 992px) {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
          .user-rate {
            display: flex;
            align-items: center;
            margin-top: 16px;
            @media (min-width: 992px) {
              margin-top: 0;
            }
            .rating {
              display: flex;
              align-items: center;
              &__star {
                margin-left: 5px;
                display: flex;
                &:first-child {
                  margin-left: 0;
                }
              }
            }
            p {
              margin-left: 8px;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: #FFFFFF;
            }
          }
        }
      }
      .subtitle {
        margin-top: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        @media (min-width: 992px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  .companies-supported {
    padding-top: 20px;
    padding-bottom: 20px;
    @media (min-width: 992px) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
    &-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 36px;
      margin-top: 30px;
      max-width: 240px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: 450px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (min-width: 650px) {
        grid-template-columns: repeat(4, 1fr);
        max-width: 510px;
      }
      @media (min-width: 800px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @media (min-width: 992px) {
        margin-top: 80px;
        max-width: none;
      }
    }
    &-item {
      align-self: center;
      justify-self: center;
      //filter: grayscale(1);
      img {
        &:last-child{
          display: none;
        }
      }

      &:hover {
        //filter: grayscale(0);
        img {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: block;
          }
        }
      }
    }
  }

  .founders-vision  {
    padding-top: 20px;
    padding-bottom: 20px;
    @media (min-width: 992px) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
    &-content {
      max-width: 450px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      background: #0F1938;
      border-radius: 10px;
      padding: 25px;
      padding-top: 40px;
      margin-top: 24px;
      @media (min-width: 992px) {
        max-width: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
      }
    }
    &-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      @media (min-width: 992px) {
        padding-left: 90px;
      }
      .photo {
        position: relative;
        &-founders {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          @media (min-width: 992px) {
            width: 180px;
            height: 180px;
          }
        }
        .quotes {
          position: absolute;
          top: -22px;
          left: -30px;
          @media (min-width: 992px) {
            width: 88px;
            height: 75px;
            left: -70px;
          }
        }
      }
      .name {
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #FFFFFF;
        margin-top: 16px;
        text-align: center;
        @media (min-width: 992px) {
          margin-top: 24px;
        }
      }
    }
    &-right {
      margin-top: 24px;
      @media (min-width: 992px) {
        max-width: 560px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        @media (min-width: 992px) {
          font-size: 18px;
          line-height: 25px;
        }
        &:first-child {
          font-weight: 700;
        }
        &:last-child{
          margin-top: 24px;
        }
      }
    }
  }
}
